
import map from './objects/map'
import debounce from './global/debounce'
import asyncLoadOnScroll from './objects/asyncLoadOnScroll'



const isDesktop = () => (window.innerWidth >= 1120)


function Map(mapElem = null) {
	this.googleMap = false
	this.mapElem = mapElem
	this.gmapObjects = []
}

Map.prototype.removeAllMarkers = function() {
	this.gmapObjects.forEach((object) => object.setMap(null))
    this.gmapObjects = []
}

Map.prototype.addMarker = function(lat, lng) {
	if (!this.googleMap) return;

	const icon = {
		url: map.mapPinLarge,
		scaledSize: new google.maps.Size(48, 74),
		origin: new google.maps.Point(0,0),
		anchor: new google.maps.Point(24,74)
	};

	const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: this.googleMap,
        icon: icon
	});
	this.gmapObjects.push(marker)
}

Map.prototype.render = function(lat, lng ,lat2, lng2) {

	const setupMap = () => {

		const hasSecondLocation = (lat2 && lng2)
		let centreLatLng = new google.maps.LatLng(lat, lng);

		const zoom = hasSecondLocation ? this.optimumZoomLevel(lat, lat2, lng, lng2) : 14;
		const minZoom = (zoom < 7) ? zoom : 7;

		if (hasSecondLocation) {
			const [ centreLat, centreLng ] = this.middlePoint(lat, lng, lat2, lng2);
			centreLatLng = new google.maps.LatLng(centreLat, centreLng);
		}		

		if (this.googleMap) this.removeAllMarkers()

		// set up map only once
		if (!this.googleMap) {
			// default set centre to lat/lng of point
			const mapOptions = map.defaultMapOptions
			mapOptions.styles = map.simpleMapStyle
			mapOptions.center = centreLatLng;
			// create map
			this.googleMap = new google.maps.Map(this.mapElem, mapOptions)
			// set max zoom level
			this.googleMap.setOptions({ maxZoom: 16 })
			this.googleMap.setOptions({ minZoom: minZoom })
			this.googleMap.setZoom(zoom);

			// add markers
			this.addMarker(lat, lng)
			hasSecondLocation && this.addMarker(lat2, lng2)

			const placeCenter = debounce(() => {
				isDesktop() && this.offsetCenterPercent(mapOptions.center, -0.25, 0)
				!isDesktop() && this.offsetCenterPercent(mapOptions.center, 0, 0)
			}, 200)

			google.maps.event.addListenerOnce(this.googleMap, 'idle', placeCenter)
			window.addEventListener('resize', placeCenter)
		}
	}
	map.load(setupMap);
}

Map.prototype.offsetCenterPercent = function(latlng, offsetX = 0.25, offsetY = 0.25) {
	const center = latlng
	const span = this.googleMap.getBounds().toSpan() // a latLng - # of deg map spans
	const newCenter = { 
	    lat: center.lat() + span.lat()*offsetY,
	    lng: center.lng() + span.lng()*offsetX
	}
	this.googleMap.setCenter(newCenter)
}


Map.prototype.middlePoint = function(lat1, lng1, lat2, lng2) {

	const toRad = x => (x * Math.PI) / 180;
	const toDeg = x => x * (180 / Math.PI);
	
    // longitude difference
    var dLng = toRad(lng2 - lng1);

    // convert to radians
    lat1 = toRad(lat1)
    lat2 = toRad(lat2)
    lng1 = toRad(lng1)

    var bX = Math.cos(lat2) * Math.cos(dLng)
    var bY = Math.cos(lat2) * Math.sin(dLng)
    var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY))
    var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX)

    return [toDeg(lat3), toDeg(lng3)]
}


Map.prototype.haversineDistance = (latlngA, latlngB, isMiles = false) => {

	// Calculates the haversine distance between point A, and B.
	// @param {number[]} latlngA [lat, lng] point A
	// @param {number[]} latlngB [lat, lng] point B
	// @param {boolean} isMiles If we are using miles, else km.

	const toRad = x => (x * Math.PI) / 180;
	const R = 6371; // km

	const dLat = toRad(latlngB[0] - latlngA[0]);
	const dLatSin = Math.sin(dLat / 2);
	const dLon = toRad(latlngB[1] - latlngA[1]);
	const dLonSin = Math.sin(dLon / 2);

	const a = (dLatSin * dLatSin) +
		(Math.cos(toRad(latlngA[1])) * Math.cos(toRad(latlngB[1])) * dLonSin * dLonSin);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let distance = R * c;

	if (isMiles) distance /= 1.60934;

	return distance;
}


Map.prototype.optimumZoomLevel = function(maxLat, minLat, maxLng, minLng) {

	const toRad = x => (x * Math.PI) / 180

	const ZOOM_FACTOR = 1.6446

	const minMapDimension = Math.min(this.mapElem.clientWidth, this.mapElem.clientHeight);
	[maxLat, minLat, maxLng, minLng] = [ maxLat, minLat, maxLng, minLng ].map((l) => toRad(l))

	const greatCircleDistance = this.haversineDistance( [maxLat, maxLng], [minLat, minLng] ) 

	const zoom = Math.floor(
		0 - Math.log(
				ZOOM_FACTOR * greatCircleDistance / 
				Math.sqrt(
					2 * (minMapDimension * minMapDimension)
				)
			) / Math.log (2)
	)

	return zoom;
}


export const initInstance = function(el) {
	const lat = parseFloat(el.getAttribute('data-lat'))
	const lng = parseFloat(el.getAttribute('data-lng'))

	const lat2 = parseFloat(el.getAttribute('data-lat2')) || null
	const lng2 = parseFloat(el.getAttribute('data-lng2')) || null

	if (!lat || !lng) return
	asyncLoadOnScroll(el, () => {
		const map = new Map(el)
		map.render(lat, lng, lat2, lng2)
	})
}
